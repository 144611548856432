




















































































































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  CmsCategoryCreateOrUpdateDto,
  CmsCategoryType,
  CreateUserDto,
  LecturerCreateOrUpdateDto,
  OrganizationUnitDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";
import { CodeToText, regionData } from "element-china-area-data";
import store from "@/store";

interface ICategoryTypeSelectItem {
  type: CmsCategoryType;
  label: string;
}

@Component({
  components: { SimpleUploadImage, Ueditor },
})
export default class CreateCmsCategory extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: LecturerCreateOrUpdateDto = {
    /**  */
    organizationUnitId: undefined,

    /** 头像 */
    headImageUrl: undefined,

    /** 姓名 */
    name: undefined,

    /** 手机号码 */
    mobile: undefined,

    /** 性别 */
    sex: undefined,

    /** 生日 */
    birthday: undefined,

    /** 省份Id */
    provinceId: undefined,

    /** 城市Id */
    cityId: undefined,

    /** 区域Id */
    areaId: undefined,

    /** 详细地址 */
    address: undefined,

    /** 简介 */
    summary: undefined,

    /** 培训次数 */
    trainCount: 0,

    /**  */
    id: 0,
  };

  cmsCategoryType = CmsCategoryType.Content;

  unitList: any = [];
  selectedOptions: any[] = ["", "", ""];
  options: any = regionData;

  sexList: any = [
    { name: "男", value: "男" },
    { name: "女", value: "女" },
  ];
  roleRule = {
    name: [
      {
        required: true,
        message: "讲师姓名 必填",
        trigger: "blur",
      },
    ],
    mobile: [
      {
        pattern: /^1[345789]\d{9}$/,
        message: "手机号码 错误",
        trigger: "blur",
      },
    ],
    sex: [
      {
        required: true,
        message: "性别必选",
        trigger: "blur",
      },
    ],
    areaId: [
      {
        required: true,
        message: "居住地址必填",
        trigger: "blur",
      },
    ],
    address: [
      {
        required: true,
        message: "详细地址必填",
        trigger: "blur",
      },
    ],
  };

  get isFoundationUser(): boolean {
    let ouId = (this as any).$store.getters["user/ouId"] || "";
    if (ouId) {
      return true;
    } else {
      return false;
    }
  }

  get hostType() {
    return AttachmentHostType.Temp;
  }

  get pageHeadTitle() {
    if ((this as any).$route.params.id) {
      return "编辑";
    } else {
      return "新增";
    }
  }

  created() {
    this.getUnitList();
    if (this.$route.params.id) {
      api.lecturer.get({ id: Number(this.$route.params.id) }).then((res) => {
        this.form = { ...res };
        this.selectedOptions = [
          res!.provinceId + "",
          res!.cityId + "",
          res!.areaId + "",
        ];
        //
        if (this.isFoundationUser) {
          this.form.organizationUnitId = (this as any).$store.getters[
            "user/ouId"
          ];
        }
      });
    }
  }

  getUnitList() {
    api.organizationUnit.getAll().then((res) => {
      this.unitList = res.items;
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.lecturer
            .update({
              body: this.form,
            })
            .then(() => {
              this.$router.back();
              this.$message.success("更新成功");
            });
        } else {
          await api.lecturer
            .create({
              body: this.form,
            })
            .then(() => {
              this.$router.back();
              this.$message.success("新建成功");
            });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  //   省市区
  handleChange(value: any, label: any) {
    this.form!.provinceId = value[0];
    this.form!.cityId = value[1];
    this.form!.areaId = value[2];
    // this.address_detail =
    //   CodeToText[value[0]] + CodeToText[value[1]] + CodeToText[value[2]];
    // this.provincecityarea =
    //   CodeToText[value[0]] + CodeToText[value[1]] + CodeToText[value[2]];
    // this.setPlace();
  }
}
